import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "my-body"
};
const _hoisted_2 = {
  class: "my-item"
};
const _hoisted_3 = {
  class: "my-card my-flex my-rows my-space-between my-nowrap"
};
const _hoisted_4 = {
  class: "my-addr"
};
const _hoisted_5 = {
  class: "my-total"
};
const _hoisted_6 = {
  class: "my-item-1"
};
const _hoisted_7 = {
  class: "my-flex my-rows my-space-between my-nowrap"
};
const _hoisted_8 = {
  class: "my-card my-flex my-rows my-space-between my-nowrap"
};
const _hoisted_9 = {
  class: "my-addr"
};
const _hoisted_10 = {
  class: "my-total"
};
const _hoisted_11 = {
  class: "my-item-2"
};
const _hoisted_12 = {
  class: "my-flex my-rows my-space-between my-nowrap"
};
const _hoisted_13 = {
  class: "my-card my-flex my-rows my-space-between my-nowrap"
};
const _hoisted_14 = {
  class: "my-addr"
};
const _hoisted_15 = {
  class: "my-total"
};
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { getReferralList } from '@/api/index.js';
import { useUserStore } from '@/stores/index.js';
export default {
  __name: 'referral',
  setup(__props) {
    const router = useRouter();
    const onClickLeft = () => {
      router.back();
    };
    const userStore = useUserStore();
    const top_id = ref();
    const list = ref([]);
    const udata = {
      user_addr: userStore.userInfo.user_addr
    };
    getReferralList(udata).then(res => {
      top_id.value = res.data.top_id;
      let resdata = res.data.data;
      resdata.forEach(item => {
        item.user_addr = item.user_addr.substring(0, 3) + '...' + item.user_addr.substring(30, 34);
      });
      list.value = flatten(resdata, top_id.value);
    });
    const flatten = (data, pid) => {
      let result = [];
      data.forEach(itema => {
        if (itema.p_id == pid) {
          result.push(itema);
          itema.sub = [];
          data.forEach(itemb => {
            if (itemb.p_id == itema.addr_id) {
              itema.sub.push(itemb);
              itemb.sub = [];
              data.forEach(itemc => {
                if (itemc.p_id == itemb.addr_id) {
                  itemb.sub.push(itemb);
                }
              });
            }
          });
        }
      });
      return result;
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_van_icon = _resolveComponent("van-icon");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
        title: "Referral",
        "left-text": "",
        "left-arrow": "",
        onClickLeft: onClickLeft,
        border: false
      }), _createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, item => {
        return _openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(item.user_addr), 1), _createElementVNode("div", _hoisted_5, "Total revenue: " + _toDisplayString(item.total_income), 1)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.sub, itemsub => {
          return _openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_van_icon, {
            name: "/image/icon/ds.png",
            size: "14",
            color: "#999",
            style: {
              "padding-right": "5px"
            }
          }), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, _toDisplayString(itemsub.user_addr), 1), _createElementVNode("div", _hoisted_10, "Total revenue: " + _toDisplayString(itemsub.total_income), 1)])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(itemsub.sub, items => {
            return _openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createVNode(_component_van_icon, {
              name: "/image/icon/ds.png",
              size: "14",
              color: "#999",
              style: {
                "padding-right": "5px"
              }
            }), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, _toDisplayString(items.user_addr), 1), _createElementVNode("div", _hoisted_15, "Total revenue: " + _toDisplayString(items.total_income), 1)])])]);
          }), 256))]);
        }), 256))]);
      }), 256))])], 64);
    };
  }
};